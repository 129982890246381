
.cmTable{
    max-height: 500px;
    overflow-y: auto;
}
.cmTable label {
    white-space: nowrap;
}
.opacityView{
    opacity: 0;
}
@media(max-width:1150px){
    .wrapFix{
        display: flex;
        flex-wrap: wrap;
    }
}
@media(max-width:470px){
    .fullWidth{
        width: 100% !important;
    }
}
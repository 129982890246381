tr.deactive{
    background-color: #ffd9dd;
}

.pointer{
    cursor: pointer;
}


.tablepadding{
    padding: 0px !important;
    margin-top: 10px;
}

.roleTable tr td {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}

.activedeactiveicon {
    font-size: 18px;
    line-height: normal;
    vertical-align: middle;
}
// i.fa.fa-trash.text-danger.d-inline.overlap_input {
//     position: absolute;
//     right: 12px;
//     top: 0;
// }
.overlap_input {
    position: absolute;
    right: 25px;
    top: 41px;
}

@media(max-width:1200px){
    .overlap_input {
        right: 8px;
    }
}
@media(max-width:480px){
    .overlap_input {
        right: 5px;
    }
}
input.form-control.radus_checkbox {
    border-radius: 0px 20px 20px 0px !important;
}
 .radius_radio_input{
    border-radius: 20px 0px 0px 20px !important;
 }

.nowrap {
    white-space: nowrap;
}

  

.modal-dialog-md {
    max-width: 900px !important;
}


// roles designs

.listing_roles {
    border-bottom:none !important;
    border-top: none !important;
}


.roles_list {
    background-color: #7561a21f;
    margin: 1rem 0px;
    padding: 0.8rem;
    border-radius: 8px;
}

h3.role_name {
    font-size: 20px;
    margin-bottom:6px;
    font-weight: 600;
    color: #7561a2;
}


.main_roles {
    font-size: 22px;
    font-weight: 600;
    color: #0000007d;
    border-top: none !important;
    border-bottom:none !important;
}


.main_roles {
    display: block;
}


.checkList label {
    margin-right: 14px;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #202020;
}


input[type="checkbox"] {
    margin-right:10px;
}tr.deactive{
    background-color: #ffd9dd;
}

.pointer{
    cursor: pointer;
}


.tablepadding{
    padding: 0px !important;
    margin-top: 10px;
}

.roleTable tr td {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}

.activedeactiveicon {
    font-size: 18px;
    line-height: normal;
    vertical-align: middle;
}


 

.nowrap {
    white-space: nowrap;
}

  

.modal-dialog-md {
    max-width: 900px !important;
}


// roles designs

.listing_roles {
    border-bottom:none !important;
    border-top: none !important;
}


.roles_list {
    background-color: #7561a21f;
    margin: 1rem 0px;
    padding: 0.8rem;
    border-radius: 8px;
}

h3.role_name {
    font-size: 20px;
    margin-bottom:6px;
    font-weight: 600;
    color: #7561a2;
}


.main_roles {
    font-size: 22px;
    font-weight: 600;
    color: #0000007d;
    border-top: none !important;
    border-bottom:none !important;
}


.main_roles {
    display: block;
}


.checkList label {
    margin-right: 14px;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #202020;
}


input[type="checkbox"] {
    margin-right:10px;
}

.qus_input {
    width: 92%;
}
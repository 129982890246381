@import './mixins';

// Colors
@import './colors';

td.table_dats {
  text-transform: capitalize;
}


.ant-picker-dropdown {
  z-index: 1056;
}

span.badges_status {
  background: #237ab9;
  color: #fff;
  padding: 2px 13px;
  border-radius: 46px;
  margin-right: 2rem;
}

span.badges_reg {
  background: #fff;
  color: #000;
  padding: 2px 13px;
  border-radius: 46px;
  margin-right: 2rem;
}

.d-flex.bg_rotter.justify-content-between {
  background: #3278b91c;
  padding: 15px 10px;
  border-radius: 15px;
  margin-bottom: 1rem;
}
.bg_rotter p {
  margin: 0;
}
img.delete_icon {
  width: 80px;
}

.bg_hover .dropdown-item.active,
.bg_hover .dropdown-item:active {
  background-color: #4aa2dc !important;
  border-radius: 8px;
  color: #ffffff !important;
  padding: 0.25rem 1rem;
  text-decoration: none;
}

.bg_hover .dropdown-item.active,
.bg_hover .dropdown-item:active {
  text-decoration: none;
  background-color: #4aa2dc !important;
  color: #fff !important;

}

.css-13cymwt-control {
  width: 154px;
  margin-top: 4px;
}

.css-t3ipsp-control {
  width: 154px;
  margin-top: 4px;
}

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  >span {
    font-size: 13px;
    color: #84818A;
  }
}

.chip {
  background: #4aa2dc;
}

.alignTopDate div.ant-picker-input {
  top: -12px;
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}

.react-pagination-js-default {
  ul {
    display: flex;
    align-items: center;
    column-gap: 10px;

    >li {
      margin: 0 !important;
      font-size: 13px !important;
      padding: 7px 13px !important;

      &:first-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #1F7BF4 !important;
        }

        &::after {
          content: "< Previous";
        }

        a {
          display: none;
        }
      }

      &:last-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #1F7BF4 !important;
        }

        &::after {
          content: "Next >";
        }

        a {
          display: none;
        }
      }

      &.page {
        border-color: #0000 !important;
        background-color: transparent !important;
        box-shadow: none !important;
        font-weight: 400 !important;
      }

      &.is-active {
        font-weight: 500 !important;
        background-color: #fff !important;
        border-color: #1F7BF4 !important;
        box-shadow: none !important;
        text-shadow: none !important;
        color: #202020 !important;
      }
    }

  }

}

body {
  font-family: $font1 !important;

}

a {
  color: #00baec;

  &:hover {
    text-decoration: none;

  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font2;
}

label {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #202020;
}

.form-row>.col,
.form-row>[class*=col-] {
  padding-right: 10px;
  padding-left: 5px;
  align-items: center;
}

.col_side div span {
  font-size: 16px;
  font-weight: 300;
  // color: #fff;
}

.removeBg {
  padding: 8px 22px 8px 16px !important;
  border-radius: 5px;
  background: #fff !important;
  border: 1px solid #0c0c0c3b !important;
  color: #000000a1 !important;
}
.removeBg:hover {
  background: #fff !important;
  border: 1px solid #0c0c0c3b !important;
  color: #000000a1 !important;


}
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
  border-color: #e9e9e9 !important;
  // box-shadow: 0 0 0 2px rgb(255 255 255 / 10%) !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker:hover,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
  border-color: #e4e4e4 !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-range .ant-picker-active-bar {
  background: #4aa2dc !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #4aa2dc !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #4aa2dc !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #4aa2dc !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #4aa2dc !important;
}

i.fa {
  cursor: pointer;
}

.dateRangePicker {
  width: 100% !important;
  border-radius: 40px;
  padding: 21px 10px;
  border: 1px solid #DCDCDC;
  height: calc(1.5em + 0.75rem + 2px);

}

.optionListContainer {
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  position: absolute;
  width: 220px;
  z-index: 9999999;
}

.ccttype {
  border-radius: 8px;
}

.table_data {
  text-transform: capitalize;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: red;
}


.text-truncate {
  width: 250px;
  white-space: nowrap;
  // padding: 20px;
  resize: horizontal;
}

.star {
  color: red !important;
  // display: none;
  // color: #202020;
}

.action_icons {
  display: flex;
}

a.edit_icon {
  // border: 1px solid #DCDCDC;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-right: 6px;

  &:hover {
    text-decoration: none !important;
  }
}


.mainarea {
  background: #F6F6F6;
  padding: 27px 0px !important;
  // height: 100vh;
  // height: calc(100vh - 72px) !important;
  // overflow: auto;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dcdcdc !important;
  outline: 0;
  box-shadow: none !important;
}

span.edit_icon {
  // border: 1px solid #DCDCDC;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-left: 3px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

i.fa.fa-eye {
  color: #4aa2dc;
}

i.material-icons.edit {
  font-size: 15px;
  font-weight: 600;
  color: #4aa2dc;

}

i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  color: red;

}

i.fa.fa-arrow-left {
  color: #000;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F9F9F9 !important;
}

.table>thead {
  vertical-align: bottom;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #fff !important;
  // text-transform: capitalize;
  white-space: nowrap;
}

th.table_data {
  border-top: none !important;
  border-bottom: none !important;
  font-size: 14px;
  font-weight: 600 !important;
  color: #25282b !important;
}

body {
  font-family: 'Lato', sans-serif;


}

.inputWrapper {
  position: relative;

  .fa {
    position: absolute;
    right: 10px;
    top: 71%;
    transform: translateY(-50%);
    color: #D2CECE;
  }
}
.changepass .fa {
  top: 50% !important;
}
.modal-title,
.dropdown-item {
  text-transform: capitalize;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #84818A !important;
  opacity: 1 !important;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.dropdown-menu.shadow.bg_hover.show {
  border: none;
  padding: 10px;
  left: -52px !important;
}

.css-1nmdiq5-menu {
  z-index: 2 !important;
}

.css-13cymwt-control {
  border-radius: 40px;
}

.searchWrapper {
  border-radius: 40px;
}

.pageLoader {
  max-width: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A0A4A8;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #A0A4A8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #A0A4A8;
}

main.main {
  background-color: #F6F6F6 !important;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

span.bg_bages {
  background: #52b1ff21;
  margin-right: 10px;
  padding: 7px 10px;
  border-radius: 50px;
  color: #56a0dc;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px !important;
  display: inline-block;
}

// Loader end

.btn {
  text-transform: capitalize;
  font-weight: 500;
  padding: 8px 18px;
  font-size: 14px;
  border-radius: 40px;
}


.btn-outline-primary {
  color: #00baec;
  border-color: #00baec;
}

a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

button.btn.btn-primary.loginclass {
  background-image: linear-gradient(186deg, #254C9D, transparent);
  border-radius: 44px;
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
}

.sidebar_scrolling {
  overflow-y: auto;
  max-height: calc(100vh - 71px);
  position: fixed;
  position: fixed;
  z-index: 999;
  background: #fff;
  margin-top: 0;
  padding-top: 2rem;
  height: 100vh;
  width: 250px;
}

@media(max-width:768px) {
  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  >.container {
    max-width: 500px;

    .shadow {
      border-top: 3px solid $primaryColor;
    }

    .login-logo {
      width: 100%;
      max-width: 150px;
    }
  }
}



.bginput {
  border: 1px solid #E8E8E8 !important;
  // background: #F6F6F6 !important;
  border-radius: 8px;
}

input.form-control.mb-0.bginput.changes {
  background-color: #fff !important;
  color: #A7A1A1 !important;
  border-color: #D2CECE !important;
  border: 1px solid #D2CECE !important;
  border-radius: 38px !important;
  height: 40px;
}

.empty-box {
  padding: 15px;
  text-align: center;

  .icon-box {
    padding: 15px 30px;
    font-size: 26px;
    margin-bottom: 10px;
  }
}

h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
  color: #444444;
}


.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #775DA6 !important;
}


::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #F6F6F6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #F6F6F6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #F6F6F6;
}


.modal {
  overflow-y: auto;

  .card-body,
  .modal-boldy {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.upload-btn {
  position: relative;

  >input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.pagination {

  >li {
    padding: 2px;

    a {
      padding: 7px 10px;
      display: inline-block;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      text-align: center;
      color: #000;
      text-decoration: none;
      background: #fff;
    }

    &.active {
      a {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
      }

    }

    &.disabled {
      a {
        border-color: #E8E8E8;
        color: #E8E8E8;
      }
    }
  }

}


.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}


.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}


@keyframes shine {
  to {
    background-position-x: -200%;
  }
}



.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
}

// edit icon css
i.fa.fa-pencil-alt.edit {
  margin: 0px 15px;
  font-size: 18px;
  color: #7561a2;
  font-weight: 700;
}

i.fa.fa-trash {
  color: #c02e2e;
  font-size: 18px;
  font-weight: 700;
}




// Responsive Design 
.buttons_Section {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.cardList {
  margin-top: 2rem;
}

a.btn.btn-primary.mr-2 {
  white-space: nowrap;
}

.form-control {
  border-radius: 40px !important;
  // padding: 21px 10px;
  border: 1px solid #DCDCDC;
  // border-radius: 8px;
}

select.form-control.types {
  height: 43px;
  margin-right: 12px;
  width: 100%;
  max-width: 200px;
}

.new_cards {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-height: 300px;
  // margin-bottom: 4.2rem;
}

input.searchbar.mr-2 {
  padding: 8px;
  border-color: #cdd4da;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cdd4da;
  height: 43px;
}

.d-flex.justify-content-between.align-items-center {
  flex-wrap: wrap;
}

@media only screen and (min-width:1200px) and (max-width:1400px) {
  .dropdown.addDropdown.chnageses {
    margin-top: 12px;
  }

  .dropdown.addDropdown.chnageset {
    margin-top: 12px;
  }

  .sales_section {
    padding: 20px;
  }

  .graph_section {
    padding: 20px;
  }

  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }
}

@media only screen and (min-width:1001px) and (max-width:1199px) {
  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }

  // .dropdown.addDropdown.chnages {
  //   margin-top: 10px;
  // }
  article.d-flex {
    margin-top: 1rem !important;
  }
}

@media only screen and (min-width:991px) and (max-width:1000px) {
  .dropdown.addDropdown.chnagesg {
    margin-top: 0px;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }


  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    padding-left: 25px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .main-wrapper main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }

}

@media screen and (max-width:630px) {

  .dropdown.addDropdown.features.mr-2 {
    margin-top: 14px;
  }
}

@media only screen and (min-width:601px) and (max-width:621px) {
  .dropdown.addDropdown.chnages {
    margin-top: 15px !important;
  }

  article.d-flex {
    flex-wrap: wrap !important;
    display: flex;
  }

  .dropdown.addDropdown.chnagest_name {
    margin-top: 13px;
  }

}

@media only screen and (min-width:601px) and (max-width:615px) {

  //   .dropdown.addDropdown.chnagesname {
  //     margin-top: 15px !important;
  // }
  article.d-flex {
    flex-wrap: wrap !important;
    display: flex;
  }
}

@media screen and (min-width:601px)and (max-width:609px) {
  .dropdown.addDropdown.region_currency {
    margin-top: 15px;
  }
}

@media only screen and (min-width:601px) and (max-width:990px) {

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    display: block !important;
  }

  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }

  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    // padding-left: 60px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  .main-wrapper main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selectDD {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .col-12.col-sm-12.col-md-12.col-lg-6.cls {
    margin-top: 15px;
  }

  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  .dropdown.addDropdown.chnagest {
    margin-left: 15px;
  }
}

@media screen and (max-width:908px) {
  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}

@media screen and (max-width:900px) {
  .dropdown.addDropdown.chnageses {
    margin-top: 15px;
  }

  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}

@media only screen and (max-width:767px) {
  .text-right {
    margin-bottom: 15px !important;
  }

  .roleTable tr td {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
  }

  .col-md-9.head_cls {
    margin-bottom: 20px;
  }

  article.d-flex {
    display: flex !important;
    margin-top: 1rem !important;
    flex-wrap: wrap;
  }

  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    display: block !important;
  }
}

@media screen and (max-width:766px) {
  .dropdown.addDropdown.chnages_date {
    margin-top: 15px;
  }
}

@media screen and (max-width:759px) {
  .dropdown.addDropdown.region_status {
    margin-top: 15px;
  }
}

@media screen and (max-width:751px) {
  .dropdown.addDropdown.chnagess {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnageses {
    margin-top: 15px;
  }
}

@media screen and (max-width:748px) {
  .dropdown.addDropdown.city_region {
    margin-top: 14px;
  }

  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}

@media screen and (max-width:738px) {
  .dropdown.addDropdown.chnageset {
    margin-top: 15px;
  }
}

@media screen and(min-width:601px) and (max-width:695px) {
  .icons_tab {
    margin-top: 0px;
  }
}

@media only screen and (min-width:528px) and (max-width:600px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
  }

  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnages_status {
    margin-top: 15px;
  }

  .dropdown.addDropdown {
    margin-top: 15px;
  }

  button.btn.btn-primary.mr-2.export {
    margin-left: 15px;
  }

  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }

  .icons_tab {
    margin-top: 15px;
  }

  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    display: block !important;
  }

  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }

  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    // padding-left: 60px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }

  // proffile dropdown
  // .dropdown.ml-auto {
  //   top: -40px;
  // }

  // .headerSearch {
  //   top: 10px !important;
  // }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .d-flex.justify-content-between.mb-3 {
    display: block !important;
  }


  p.mb-0 {
    font-size: 13px;
  }

  img.listIc {
    height: 40px;
    width: 40px;
  }

  form.headerSearch.ml-3 {
    width: auto;
    max-width: 130px;
  }

  // .ml-3 {
  //   width: 300px;
  // }
  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  article.d-flex {
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  button#dropdownMenuButtonstatusDropdown\ reset {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnages {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnagest {
    margin-top: 15px;
  }

}

@media screen and (max-width:578px) {
  .dropdown.addDropdown.chnagesapp {
    margin-top: 15px;
  }
}

@media screen and (max-width:590px) {
  button.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  a.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

}


@media only screen and (min-width:320px) and (max-width:527px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
  }

  .icons_tab {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }

  .min-sidebar~div>.main-sidebar {
    display: none !important;

  }

  .main-navbar.min-sidebar {
    // padding-left: 40px;
    display: flex;
  }

  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .main-navbar {
    // padding-left: 40px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar~div>main.main {
    width: 100% !important;
  }

  // proffile dropdown
  .dropdown.ml-auto {
    // top: -30px;
    // right: -16px;
  }

  .headerSearch {
    // top: 20px !important;
  }



  // main p-age


  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .nav-tabs {
    border-radius: 3px !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
    margin-top: 1rem;
  }
  .userstabs {
    border: 1px solid #dee2e6!important;
    border-radius: 40px !important;
    height: auto !important;
}

  .icons_tab {
    margin: 0px !important;
  }

  .nav-tabs {
    border-bottom: 1px solid #ffffff;
  }

  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }

  .new_cards {
    height: 100% !important;
    margin-bottom: 0rem !important;
    max-height: 500px !important;
    //  height: 100%;
    //   margin-bottom: 0rem !important;
  }

  li.list_names {
    text-align: left !important;
  }

  .user_proff {
    display: block !important;
  }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  select.form-control.types {
    margin-top: 13px;
    width: 100%;
  }

  // i.fa.fa-eye {
  //   color: #775da6;
  // }

  .d-flex.justify-content-between.mb-3 {
    display: block !important;
  }

  .d-flex.justify-content-between {
    display: block !important;
  }

  button.btn.btn-primary.btnreset {
    margin-top: 15px;
  }


  // dashboard

  h2.mb-1.mainHeading_cls {
    font-size: 20px;
    font-weight: 600;
  }

  p.grayCls.mb-0 {
    font-size: 12px;
    font-weight: 200;
    color: #898989;
    width: auto !important;
  }

  a.btn.barlink.text-primary {
    // left: 8px;
    // width: 35px;
    // height: 35px;
    // padding: 7px 9px;
    left: 19px;
    width: 35px;
    height: 35px;
    padding: 7px 9px;
    top: 17px;

  }

  form.headerSearch.ml-3 {
    // width: 100% !important;
    // margin-left: 9px !important;
  }

  p.mb-0 {
    white-space: nowrap;
    // width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // .ml-3 {
  //   width: 200px;
  // }
  img.listIc {
    height: 40px;
    width: 40px;
  }

  .borderBox.d-flex.align-items-center.justify-content-between {
    padding: 0px 15px;

  }

  .timeBoxs p {
    font-size: 14px;
    color: #484848;
    line-height: 17px;
  }

  .headerSearch .Searchbar {
    width: 100%;
    max-width: 130px;
  }

  // form.headerSearch.ml-3 {
  //   width: 130px;
  // }

  // dashboard responsive
  .graph_range {
    display: block;
  }

  p.view_graph {
    margin-top: 18px;
  }


  // admin user
  .d-flex.justify-content-between.align-items-center {
    display: block !important;
  }

  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  article.d-flex a,
  button {
    margin-bottom: 1rem !important;
  }

  button.btn.btn-primary.dropdown-toggle.removeBg {
    margin-bottom: 0px !important;
  }

  .table_section {
    // margin-top: 10px !important;
  }

  .mr-2.phBottomSpace {
    margin-bottom: 10px;
  }

  a.btn.btn-primary.profiles {
    width: fit-content;
  }

  button.btn.btn-primary.mr-2.export {
    margin-left: 15px;
  }

  .hedding {
    font-size: 21px;
  }
}

@media only screen and (min-width:320px) and (max-width:441px) {
  button.btn.btn-primary.mr-2.exports {
    margin-top: 15px;
  }

  h5.heading_holidays {
    margin-top: 30px !important;
  }
}

// profile changes
input.searchbar.mr-2:focus {
  box-shadow: 0 3px 10px 0 17%;
  border-color: #7561a2;
}

a.btn.btn-primary.profiles {
  background-color: #fff !important;
  border: 1px solid #c2c2c2 !important;
  height: auto;
  color: #2020209c !important;
  border-radius: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

i.material-icons.prob {
  font-size: 27px;
  margin: 0px 7px;
  color: #0000004a;
}

label.btn.btn-primary.delete.ml-3 {
  background-color: #fff !important;
  border-radius: 10px;
  // color: #7561a2 !important;
  color: #56a0dc !important;
  font-size: 14px !important;
  font-weight: 500;
}

label.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}

.btn.btn-secondary.discard {
  background-color: #fff !important;
  border: 1px solid #DCDCDC !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 43px;
  border-radius: 40px;
  font-weight: 500;

}

a.discard {
  text-decoration: none;
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;

  & :hover {
    color: #202020 !important;
    font-size: 14px;
    font-weight: 500;
  }
}

.btn.btn-primary.reset {
  background-color: #fff !important;
  border: 1px solid #DCDCDC !important;
  color: #202020 !important;
  font-size: 14px !important;
  // height: 43px;
  border-radius: 10px;
}

button.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;

}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: #202020 !important;
  --bs-table-bg-type: rgb(249 249 249 / 100%) !important;
}

a.btn.barlink.text-primary.active {
  outline: none;
  border: none;
}

a.btn.barlink.text-primary:active {
  border: none;
}

p.profile_data {
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 400;
  color: #5A5A5A;
}

.col-md-12.inputFlex {
  line-height: 30px;
}

.highlightOption {
  background: #0096fb;
  color: #fff;
}

.multiSelectContainer li:hover {

  background: #0096fb !important;
  color: #fff;
}
.sidees{
  display: flex;
align-items: center;
}



@media screen and (min-width:320px) and (max-width:1000px) {
  .min-sidebar~div>.main-sidebar a.col_side:hover {
    justify-content: center;
    margin: 8px 14px !important;
  }
  .barWidth{
    width: auto !important;
  }

  .min-sidebar~div>.main-sidebar {
    display: block !important;
    max-width: 250px;
    min-width: 250px;
    // width: 100%;
    // min-height: 100vh;
    // margin-top: -72px;
    // border-right: 1px solid #E8E8E8;

  }

  .sidees {
    display: flex;
  }

  .min-sidebar~div>.main-sidebar a.nav-link.hoverclass {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: left !important;
    width: auto !important;
  }

  .min-sidebar~div>.main-sidebar a.col_side {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    width: auto !important;
  }

  .min-sidebar~div>.main-sidebar .nav-link span {
    display: block;
  }

  .min-sidebar~div>.main-sidebar a.col_side span {
    display: flex;
    font-size: 15px;
    font-weight: 300;
    // color: #202020;
  }

  .min-sidebar~div>.main-sidebar i {
    margin-right: 8px !important;
  }

  .main-sidebar {
    display: none !important;
  }

  .main-sidebar .nav .nav-item .nav-link i.sidenv {
    display: none !important;
  }

  .card.card-body.sides li a {
    border-radius: 4px !important;
    padding-left: 42px !important;
  }

  .mainarea {
    overflow: auto;
    padding: 20px !important;
  }
}
@media(max-width:480px){
  .top_section {
    padding: 5px;
  }
  .col-md-12.inputFlex {
    flex-direction: column;
    align-items: start;
}
.suretext{
  font-size: 17px;
}
.userstabs .nav-link {
  padding: .4rem 0.7rem;
}
}
@media(min-width:768px) {
  .main-navbar.min-sidebar~div>.main-sidebar {
    display: block !important;
  }
}

@media(min-width:992px) {
  .pickerwidth {
    width: 28%;
  }
}

/* FloatingChatIcon.css */

.floating-chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #2c2d2f;
  color: #fff;
  padding: 10px;
  border-radius: 31%;
  cursor: pointer;
  font-size: 29px;
  z-index: 9;
}


// Contact us page
.contact_card {
  border: 1px solid #80808054;
  padding: 30px 20px 40px 20px;
  border-radius: 10px;
  background: #fff;
}

textarea.form-control.area_contact {
  min-height: 135px;
}

.view_p {
  background: #80808014;
  padding: 0px 15px;
  border-radius: 10px;
  font-size: 12px;
  height: 35px;
  display: flex;
  align-items: center;
}

.report_heading {
  color: #000;
  font-size: 22px;
  margin: 0 !important;
} 

.employer_filt {
  background: none !important;
  color: rgba(0, 0, 0, 0.88) !important;
  border: 1px solid #d9d9d9 !important;
  padding: 10px 20px !important;
  // min-width: 175px;
  border-radius: 40px;

}

.employer_filt.dropdown-toggle::after {
  display: none !important;
}

.employer_filt:hover {
  border-color: #00a5d9 !important;
}

.text_limit_table {
  max-width: 270px;
  white-space: normal;
}

.cardit {
  background: #fff;
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cmc_table label {
  margin: 0;
  padding: 10px 0px;
  font-weight: 600;
}

.firstcms {
  border-top: 1px solid #80808029;
  border-right: 1px solid #80808029;
}

.cmc_table {
  border-right: 1px solid #80808029;
  padding: 0px 14px;
  border-left: 1px solid #80808029;
}

.cmc_table .col-3 {
  border: 1px solid #80808029;
  padding: 5px;
  border-top: 0px;
  border-right: 0;
}

.cmc_table .col-2 {
  border: 1px solid #80808029;
  padding: 5px;
  text-align: c;
  border-top: 0px;
  border-right: 0;
}

.cmc_table p {
  display: flex;
  // justify-content: center;
  align-items: center;
  margin: 0;
  padding: 5px 0;
  font-size: 13px;
  padding-left: 5px;
}

.btable .table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #80808029 !important;
  // text-transform: capitalize;
  white-space: nowrap;

}

.btable .table th {
  font-weight: 600;
  text-align: center;
  font-size: 13px;
}

.btable .table td {
  font-size: 13px;
}

.cmc_table2 {
  border: 1px solid #80808029;
  padding: 0px 15px;
}

.firstcms2 {
  border-bottom: 1px solid #80808029;
}

.cms3 {
  border-bottom: 1px solid #80808029;
}

.cmc_table2 label {
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
  font-size: 12px;
}

.cmc_table2 p {
  font-size: 13px;
  margin: 0;
  padding: 10px 0;
  text-align: center;
}

.cmc_table2 .col-3,
.col-2 {
  border-right: 1px solid #80808029;


}



.cmc_table2 .npms {
  background: #80808047;
  margin: 10px 0px;
}

.backit {
  background: #f1b0a9;
  margin-bottom: 10px !important;
  padding: 10px !important;
}

.backitagin {
  background: #bcd7f1;
  margin-bottom: 10px !important;
  padding: 10px !important;
}

.btable td {
  text-align: center;
}

// .btable2 .backit,
// .backitagin {
//   // margin-bottom: 0  !important;
// }

.cmc_table2 {
  max-height: 500px;
  overflow-y: scroll;
}

.firstcms2 {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;
}

.btable {
  max-height: 400px;
  overflow-y: auto;
}

.border_top {
  border-top: 1px solid #dee2e6;
}

.border_top .table th {
  border-top: 1px solid #fff !important;
}

.border_top .table thead {
  top: -1px !important;
}

.backitagindiv {
  width: 180px;
  text-align: center;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000c7;
  background: #47a0dc69;
  border-radius: 5px;
}

.backitshadow {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.totalh {
  font-weight: 600;
}

.spanhour {
  font-size: 14px;
}

span.fontw {
  font-weight: 600;
}

.pinkbg {
  // background: pink;
  background: #e8533470;
}

.graybgp {
  background: #63c37275;
}

.sixfont {
  font-weight: 600;
}

.minbt {
  min-width: 95px;
}

.textep {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  margin: 0;
}

.reasionborder {
  border-radius: 5px !important;
}

.rejectedimg {
  height: 85px;
}

article .removeBg.dropdown-toggle:after {

  top: 17px !important;
}

.addDropdown .removeBg.dropdown-toggle:after {
  top: 17px !important;
}

.backhead {
  font-size: 15px;
  font-weight: 600;
  color: #fff !important;
  background: #4aa2dc;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}

.backheadarrow {
  color: #fff !important;
  background: #4aa2dc;
  padding: 8px;
  border-radius: 5px;
  font-size: 15px;

}

.uploadimageag {
  height: 50px;
}

img.documentimg {
  height: 80px;
  width: 90px;
  border-radius: 5px;
}

.weightlabel {
  font-weight: 600;
}

.biggercheck {
  height: 15px !important;
  width: 15px !important;
}

input.customboxc.biggercheck {
  margin-left: 8px;
  margin-right: 13px;
}

.userstabs {
  padding-left: 3px;
  border-radius: 40px;
  background: #fff;
}

.userstabs .usertabcolor {
  color: #fff !important;
  border-radius: 0 !important;
  background: #4aa2dc !important;
  margin: 2px 2px 3px 0px;

}

.userstabs .nav-link {
  font-size: 14px;
  border-radius: 40px !important;
}

.nodatath {
  font-size: 19px;
}

@media(max-width:758px) {
  .userstabs {
    margin-top: 10px;
  }
  .buttonTabs {
    margin-top: 10px;
  }
}

.lightyellowbg {
  background: #ffa50082;
}

.t_padding {
  display: block;

}
.lineit{
  line-height: 2;
}
@media(max-width:480px){
  p.profile_data {
    font-size: 13px;
    line-break: anywhere;
    line-height: 20px;
}
}
.inv {
  background: #00a6d93b;
  color: #00a6d9;
  padding: 3px 5px;
  border-radius: 2px;
  font-size: 12px;
      width: 75px;
    text-align: center;
}
.reg{
  background:#00800042;
  color: green;
  padding: 3px 5px;
  border-radius: 2px;
  font-size: 12px;
}
.on_status {
  background: #80808082;
  color: #0000009e;
  padding: 3px 5px;
  border-radius: 2px;
  font-size: 12px;
      width: 70px;
    text-align: center;
}
.onb {
  background: #ff5033b3;
  color: #000000b8;
  padding: 3px 5px;
  border-radius: 2px;
  font-size: 12px;
      width: 70px;
    text-align: center;
}
.adjust {
  padding-top: 8px;
  display: block;
}
.table_cons {
  span.edit_icon {
  width: 22px !important;
}
i.fa.fa-eye {
  font-size: 15px !important;
}
i.fa.fa-trash {
  font-size: 13px !important;
}
}
.pad_th {
  padding-left: 105px;
}
.heightcr{
  height: 100vh;
}
.heightcr{
  .inputWrapper .fa {
    top: 47% !important;
    font-size: 15px;
  }
}
.timeZone .css-13cymwt-control {
  width: 100% !important;
  border-radius: 40px !important;
  border: 1px solid #DCDCDC !important;
  margin-top: 0  !important;
}
.timeZone .css-t3ipsp-control{
  width: 100% !important;
  border-radius: 40px !important;
  border: 1px solid #DCDCDC !important;
}
.timeZone .css-t3ipsp-control:active{
  border: 1px solid #DCDCDC !important;
}
.timeZone .css-t3ipsp-control:hover{
  border: 1px solid #DCDCDC !important;
}
.contractFirst{
  display: flex;
  gap: 15px;
  width: 100%;
  overflow-x: auto;
}
.contractTabs{
 overflow-x: auto;
  width: 100%;
  gap: 10px;
}
.contractTabs .form-control{
  min-width: 90px !important;
  margin-bottom: 15px !important;
  font-size: 14px;
}
.timingsP{
  padding-top: 7px;
  font-size: 17px;
  white-space:nowrap;
}
.timingsP1{
  padding-top: 12px;
  font-size: 17px;
  white-space:nowrap;
}
.timingsP2{
  padding-top: 14px;
  font-size: 17px;
  white-space:nowrap;
}

@media(max-width:950px){
  .contractTabs .form-control{
    min-width: 85px !important;
  }
}
.cont_table .form-control{
font-size: 13px !important;
min-width: 85px !important;
}
.cont_table th {
  background-color: #f2f3f4;
}
.heightVH{
  height: 100vh;
}
.plusAdd{
  font-size: 11px;
}
.actiondropBtn{
background: none !important;
border: none !important;
  }
.actiondropBtn::after{
display: none !important;
}
.actiondropBtn.active{
outline: none !important;
  }
.actiondropBtn:active {
    border: none;
}
.actiondropBtn a{
  display: flex;
  gap: 10px;
}
.actiondropBtnUl {
  min-width: 5rem !important;
}
.actiondropBtnUl .dropdown-item{
  display: flex;
  justify-content: center;
}
.actiondropBtnUl .dropdown-item a{
  display: flex;
  gap: 6px;
}
.actiondropBtnUl .dropdown-item span{
  display: flex;
  gap: 6px;
}
.delProfile{
  background-color: #fff !important;
  border-radius: 10px;
  color: #56a0dc !important;
  font-size: 14px !important;
  font-weight: 500;
}
.delProfile:hover{
  background-color: #fff !important;
  color: #56a0dc !important;
}
.chnagePro{
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}
.fontAdjust{
  font-size: 12px !important;
}
.mobileInputRadius .react-tel-input .flag-dropdown {
  border-radius: 40px 0px 0px 40px !important;
}
.cmcTableScroll{
  width: 100%;
  overflow: auto;
  border: none !important;
  padding: 0 !important;
  max-height: calc(100vh - 150px);
  overflow: auto;
}
.table_sectionCmc{
  background: #fff;
  box-shadow: 0px 0px 2px #cccccc40 !important;
  padding-left: 20px;
}
.cmcTableHeader{
  position: sticky;
  top: 0;
  background: #fff;
  width: 100%;
}
.colRowWidth.th{
  background: #fff;
}
.cmcTableScroll .colRowWidth {
  min-width: 130px;
  max-width: 130px;
  // text-align: center;
}
.cmcTableScroll p {
  justify-content: start !important;
}
.clearOpacity{
  opacity: 0;
}
.datePickeWidth {
  width: 202px;
}
@media(max-width:564px){

}
@media(max-width:477px){

  .mWidthFull .employer_filt {
    margin-bottom: 0 !important;
  }
}
.iconsWidthAdjust{
  width: 0 !important;
}
.virtualNamesTable td{
  vertical-align: middle;
}
.virtualNames{
  font-size: 13px;
  text-align: center;
  background: #f1b0a9;
  margin-bottom: 0px !important;
  padding: 10px !important;
}
.virtualNamesTwo{
  font-size: 13px;
  text-align: center;
  background: #bcd7f1;
  margin-bottom: 0px !important;
  padding: 10px !important;
}

.moneyButtons{
  padding: 6px 10px;
  font-size: 12px;
  min-width: 75px;
}
.articleReverse{
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px 0px;
}
.normalFlex{
  display: flex;
justify-content: space-between;
flex-wrap: wrap;
gap: 2px;
}
@media(max-width:440px){
  .fullWidth{
      width: 100% !important;
      margin-left:0 !important;
      margin-right:0 !important;
  }
}
.cmcRowAdjust{
  min-width: 830px;
  overflow-x: auto;
}
.documentUpload_image {
  cursor: pointer;
    height: 90px;
    width: 90px;
    object-fit: cover;
}
.dropdownRadius .css-13cymwt-control {
border-radius: 40px !important;

}
.jobsMultiSelect .searchWrapper {
  background: #fff;
}
.jobsMultiSelect .multiSelectContainer input {
  background: #fff;

}
.dropdownRadius  .css-13cymwt-control {
margin-top: 0 !important;
}
.weightdata{
  font-size: 13px;
  background: #80808012;
  padding: 3px 10px;
  border-radius: 3px;

}
.testmonialTable table td{
  vertical-align: middle;
}
.fontBack {
  font-size: 14px;
  background: #3ea1d9;
  padding: 10px 12px;
  border-radius: 4px;
  color: #fff !important;
  margin: 0 6px 0 0;
}
.whiteit{
  white-space: nowrap;
}
.errorFont{
  font-size: 14px;
  margin-bottom: 12px;
}
.modalBorderBottom {
  border-bottom: 1px solid #8080804a;
  margin-bottom: 13px;
}
h5.modal-title{
  font-weight: 600;
}
.reviewButton {
  border:1px solid  #4aa2dc !important;
  color: #4aa2dc !important;
  background: none;
  padding: 6px 10px;
  font-size: 12px;
  min-width: 75px;
  border-radius: 30px;
}
.reviewButton:hover {
  color: #fff !important;
  background-color: #4aa2dc !important;
  border-color: #4aa2dc !important;
}
.colorit{
  color: #4aa2dc;
}

.ddate .ant-picker {
  width: 100%;
  border-radius: 38px;
  padding: 6px 13px;
}

.ddate {
  margin-top: 1px;
}
p.dots_tabls {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 805px;
  margin-bottom: 0px;
  white-space: normal;
}


  

td.table_dats.dddd {
  width: 100px;
}

// .cmc_table p{
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
// }

span.seemore {
  color: #6ca2db;
}

.tablsss{
  width: 805px;
  line-break: anywhere;
  word-wrap: break-word;
  white-space: normal;
  margin-bottom: 0px;

}

.seemore{
  color: #6ca2db;
}

// .dots_tab {
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   width: 149px;
//   overflow: hidden;
// }
.tablbx {
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
}
.hard_tabs{
  display: flex;
  gap: 4px;
  width: 581px;
  overflow: hidden;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.skills_sofs{
  margin-left: 12px !important;
}

.hard_tabs span {
  border: 1px solid #dddada;
  margin-right: 5px;
  padding: 2px 6px;
  border-radius: 23px;
  background-color: #4aa2dc;
  font-size: 13px;
  color: #fff;
}

.p_tagss {

  width: 103px;
}

.harrd_skills input.form-control {
  padding-right: 33px;
}

.harrd_skills span.edit_icon {
  position: absolute;
  right: 13px;
  top: 4px;
  font-size: 13px;
  color: red;
}

.all_selects .css-t3ipsp-control {
  border-radius: 40px !important;
  border-color: gray !important;
  box-shadow: unset !important;
  margin-top: 0px !important;
}
.fullWidth ul.dropdown-menu.show {
    max-height: 200px;
    overflow-y: auto;
}

// 

.clocktimes .react-datepicker__header.react-datepicker__header--time {
  padding: 20px  0px !important;
}

.clocktimes ul.react-datepicker__time-list {
  max-height: 174px !important;
  height: 100% !important;
}

.clocktimes .react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px !important;
  width: 68vh !important;
}
.table-new p {
  padding: 0 !important;
  color: #000000b8 !important;
}
.table-new .cmcRowAdjust {
  padding: 10px 0;
}
.table-new .row.cms.firstcms.cmcRowAdjust {
  background: #efefef;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.table-new .cmcRowAdjust > div:first-child p ,.table-new .cmcRowAdjust > div:first-child label{
  width: 275px;
}
.table-new .cmcRowAdjust > div:first-child p {
  color: #3a6d8b !important;
  font-weight: 500;
}
.table-new .cmcRowAdjust{
  min-width: 1300px;
}
.set_serchbx .headerSearch input {
  padding: 12px 32px !important;
  width: 100%;
}
.userstabs {
  padding-left: 3px;
  border-radius: 40px;
}

.main_usertabs {
  background-color: #fff;
}

//edit user css 

.role_dropdown button , .role_dropdown button:hover, .role_dropdown button:focus, .role_dropdown button:active {
  width: 100% !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #cacaca !important;
  text-align: left !important;
}



.imagethumbWrapper .fa-times:hover {
  background-color: #9c9fa2;
  color: #fff;
}



.role_dropdown .dropdown-menu.shadow.bg_hover.show {
    border: none;
    padding: 10px;
    width: 100%;
     left: 0px !important;
}

.dropdown-item.active {
  background-color: #4aa2dc !important;
}
.dropdown-item:focus, .dropdown-item:hover{
  color: #fff !important;
  background-color: #4aa2dc !important;
  border-radius: 8px;
}
.fullWidth a.dropdown-item {
  margin: 3px 0;
}
.dropdown-item:focus-visible {
border: 0px !important;
}
.dropdown-item{
  color: #000 !important;
}


.employer_filt {
  background-color: #fff !important;
}

.new-drop li a.dropdown-item {
  // display: block;
  // width: 254px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
 line-break: anywhere;
  font-size: 12px;
  white-space: inherit !important;
}
.new-drop {
  max-height: 200px;
  overflow-y: auto;

  width: 300px;
  margin-right: auto;
}

.payment_dropdown .dropdown-menu.show{
  max-height: 200px;
  overflow-y: auto;
}
.custom-flex{
  display: flex;
}
.h-searchOne{
  top: -2px
}
.sidees span {
  font-size: 15px;
}
.sidebar_scrolling li.nav-item span {
  font-size: 15px;
  
}
.main-sidebar .collapsed div i.material-icons.mr-2{
  font-size: 17px;
  color: #0000009c;
}
.main-sidebar a.col_side:hover i.material-icons{
  color: #fff !important;

}
.main-sidebar .col_side div span {
  font-size: 15px;
  font-weight: 300;
}
.main-sidebar .nav .nav-item .nav-link i {
  font-size: 17px;
  color: #0000009c;
}
.main-sidebar .side_icon i{
  font-size: 16px;
}
.black-screen {
  background: #0000003d;
}
.react-switch-bg svg {
  display: none;
}

.react-switch-bg {
  background: #3ea1d9 !important;
  height: 20px !important;
  width: 48px !important;
}
.deactive .react-switch-bg {
  background: #00000061 !important;
}
.react-switch-handle {
  width: 17px !important;
  height: 17px !important;
}
.deactive-modal img {
  width: 75px !important;
  margin: 0 0 20px 0;
  filter: opacity(0.5);
}
.new-switch .form-switch .form-check-input{
  margin-left: 0 !important;
}
.new-switch .form-check {
  padding-left: 0;
}

.new-switch .form-switch .form-check-input {
  width: 48px !important;
  height: 20px;
}
.new-switch  .form-check-input {
  background-color: #9512122e;
  border: var(--bs-border-width) solid #dfbba1;
}
.new-switch  .form-check-input:checked {
  background-color: #3ea1d9;
  border-color: #3ea1d9;
}
span.contracts-highlight {
  color: #000000bf;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0  10px;
}
.table_section .no-data-sect {
  border: 0 !important;
}
.memo_radius {
  border-radius: 7px !important;
}
.pagination{
  justify-content: center;
}
.multiSelectContainer li:hover {
  background: #3ea1d9 !important;
  color: #fff;
}
.highlightOption {
  background: #3ea1d9 !important;
  color: #fff;
}
label.add_portfolio.edit {
  border: 1px dashed #0000004a;
  width: 100% !important;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
label.add_portfolio.edit input {
  width: 100%;
  height: 43px;
}
a.dropdown-item {
  margin: 2px 0;
}
span.add_portfolio i {
  color: #00000078;
}
.text-truncates p {
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 200px;
  margin: 0;
  overflow: hidden;
  height: 20px;
}



.calendar-main .rdrDateRangePickerWrapper {
    position: absolute;
    z-index: 999;
    
    border-radius: 3px;
  
   top: 0;
}
.rdrInputRanges{
  display: none;
}
.rdrMonth {
  width: 22em !important;
}
.rdrDefinedRangesWrapper {
    width: 111px !important;
}
.rdrDateDisplayWrapper {
    display: none !important;
}
.calendar-main  button.btn.btn-primary {
 text-align: end;
}
.calendar-main {
  width: 641px;
  position: absolute !important;
  height: 341px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #0097fa5c;
  z-index: 99;
}
.calendar-apply {
   
    background: #fff;
    position: absolute;
    z-index: 99;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 6px;
}
.calendar-apply h2 {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
}
.calendar-apply button{
  font-size: 13px;
  padding: 5px 13px;
}
.calendar-n {
  display: flex !important;
  flex-wrap: nowrap !important;
  height: 42px !important;
  justify-content: space-between;
  align-items: center;
}
.calendar-n p {
  margin: 0 !important;
  font-size: 13px;
  line-height: 30px;
}

.calendar-main .rdrWeekDay {
    font-weight: 400;
    line-height: 1.667em !important;
    color: rgb(132, 144, 149);
}
.calendar-main .rdrMonth {
  padding: 0 0.833em 0em 0.833em !important;
}

.headerSearchs > i {
  position: absolute;
  top: 50%;
  color: #84818A;
  transform: translateY(-50%);
  cursor: pointer;
}
.flex-prop{
  display: flex; 
  align-items: center;
  justify-content: space-between;
}
.delete-box i {
  font-size: 12px !important;
  background: #efefef !important;
  padding: 12px !important;
  border-radius: 5px;
  margin: 0 0 0 6px;
}
.ques-type {
  margin: 20px 0 0 0;
  
  
}
.badge-info-custom {
  border-radius: 50px;
}
.search-radius .searchWrapper {
  border-radius: 12px;
}
.ques-inner{
  background: #3ea1d90d;
}
.flex-title{
  font-size: 18px;
  font-weight: 600;
}
@media screen and (max-width:1199px){
  .report_heading{
    font-size: 18px;
  }
}
@media screen and (max-width:991px){
  .calendar-main .rdrMonths {
    display: flex;
    flex-direction: column;
}
.calendar-main {
  width: 403px;
  position: absolute !important;
  height: 588px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #0097fa5c;
}
}
@media screen and (max-width:767px){
.ant-picker-panels {
  flex-direction: column;
}
.calendar-main  .rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 8px 5px;
  text-align: left;
  font-size: 12px;
}
.calendar-main  .rdrDefinedRangesWrapper {
  width: 77px !important;
}
.calendar-main .rdrDayNumber span{
  font-size: 12px !important;
}
.calendar-main .rdrDay{
  margin: 0 !important;
}
.calendar-main {
  width: 327px;
  height: 565px;
}
.calendar-main .rdrCalendarWrapper {
  color: #000000;
  font-size: 11px;
}
.calendar-main .rdrNextPrevButton {
  display: block;
  width: 23px;
  height: 22px;
  margin: 5px;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7;
}
.calendar-main .rdrMonthAndYearPickers select {

  padding: 4px 25px 10px 5px;
 
}
.set_serchbx {
  margin: 10px 10px 10px 0 !important;
  width: 100%;
}
.report-btn{
  width: 100%;
  justify-content: space-between;
  display: flex;
}
.report-btn button{
  width: 49%;
  margin: 0;
}
.flex-direction-set{
  flex-direction: column;
}
}
svg.delete-icon {

  color: red;
  cursor: pointer;
}
.css-13cymwt-control {
  width: 100% !important;
  border-radius: 50px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
span.visaText {
  font-size: 18px;
  font-weight: 600;
}
.badge.badge-primary {
  background-color: #765aa6 !important;
  color: #ffffff !important;
  font-weight: 100;
  border-radius: 50px;
  padding: 6px 10px;
}
.btn-outline-primary:hover { 
  background-color: #31a3d9;
  border-color: #31a3d9;
}
.dropdown-menu.shadow.bg_hover.leftAlignDrop.leftBilling {
  left: 0 !important;
}
.removeBg.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 9px;
  top: 20px;
}
.billing_tab li a{
  padding: 7px 10px !important;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pointer{
  cursor: pointer;
}
img.visa {
  // width: 60px;
  // height: 35px;
  width: 65px;
  object-fit: contain;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}


/* YourCreditCardForm.css */

.credit-card-form {
  width: auto; /* Adjust the width as needed */
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.inputc {
  width: 400px !important;
  border-radius: 1% !important;
 
  border: 1px solid #ccc; 
  font-size: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-items: center;
}

/* Add more styling as needed */
.primarycard_del{
  width: 120px !important;
  height: 80px;
}
.pricard_header{
  font-size: 16px !important;
  font-weight: 600 !important;
}

@media(max-width:480px){
  .card-title.mb-0{
    margin-top: 10px;
  }
  .card_headerfont{
    font-size: 19px;
  }
  .change_width .btn{
    padding: 5px 10px;
    font-size: 12px;
  }
  .main-wrapper main.main{
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media(min-width:767px){
  .change_width{
    width: 75%;
  }
}

tr.deactive{
    background-color: #ffd9dd;
}

.list_name{
  min-width: 90px;  
}

.activedeactiveicon {
    font-size: 18px;
    line-height: normal;
    vertical-align: middle;
}

 

.profiledetailscls {
    background: #97c5e226;
    padding: 8px 15px;
    border-radius: 50px;
}
.mobilalnumber {
    width: 100%;
    position: absolute;
    left: 104px;
    padding: 3px !important;
    border-left: none !important;
    border: none !important;
    height: 35px;
    margin-top: 2px;
}

.mobilalnumber:focus {
    width: 100%;
    position: absolute;
    left: 104px;
    padding: 0px !important;
    border-left: none !important;
    
}


.react-tel-input .form-control:focus {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #fff !important;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 38px; 
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #fff !important;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 38px;
    width: 100% !important;
    outline: none;
}

// img.rounded-circle.mr-1.prop_img {
//     width: 37px;
//     height: 37px;
// }

.nowrap {
    white-space: nowrap;
}

  

.modal-dialog-md {
    max-width: 900px !important;
}
.rejected{
    color: red;
    // font-weight: bold;

}
.accepted{
    color: green;

}
.pending{
    color: #677cad;
}
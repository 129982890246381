.icons_tab .nav-tabs .nav-link {
    color: #197d9f;
    // padding: 4px;
}
// main css
h2.main_heading {
    font-size: 36px;
    font-weight: 600;
    color: #202020;
  }
  
  .main_header {
    display: flex;
    justify-content: space-between;
  }
  img.apps {
      width: 33px;
      height: 33px;
      border-radius: 50%;
      object-fit: contain;
  
  }
  .side_btns {
    display: flex;
    justify-content: space-between;
  }
  .icons_tab { 
    .nav-link{
      &.active{
        color: #fff !important;
      }
    }

    .nav{
      flex-wrap: nowrap !important;
    }
  }
  .icons_tab .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #fff #fff #fff;
    outline: none !important;
  }
  .icons_tab .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius:0px;
    background: #fff;
    padding-bottom: 0px;
    height: 35px;
    padding-top: 0px;
  }
  
  .icons_tab .nav-tabs .nav-item .nav-link, .icons_tab .nav-tabs .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    border-right: 1px solid #E8E8E8;
    border-top: 0px;
    border-left: 0px;
    border-bottom: 0px !important;
    height: 40px;
      padding-bottom: 0px;
  
  }

  .icons_tab .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.staff.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    border-right: 1px solid #ffff;
    border-top: 0px;
    border-left: 1px solid #E8E8E8;
    border-bottom: 0px !important;
    height: 40px;
    border-radius: 0px;
      padding-bottom: 0px;
  
  }
  .icons_tab .nav-tabs {
    border: 1px solid #E8E8E8;
    padding: 0px 0px;
    border-radius: 3px;
    background: #fff;
    height: 43px;
    width: max-content;
  }

.dotdrop {
    background: #fff !important;
    border: none;
}
  
  .main_section {
    height: 100%;
    padding:0px;
  }
  ul.navbar-list {
    display: flex;
    list-style-type: none;
    padding: 0px;
    text-align: left;
    border-bottom: 1px solid #E8E8E8;
    line-height: 37px;

  }
  .icons_tab a.nav-link.active {
    color: #202020;
    font-size: 16px;
    padding: 7px 0px;
    font-weight: 500;
    border-bottom: 2px solid #775DA6;
}
.icons_tab a.nav-link {
    font-size: 16px;
    font-weight: 400;
    color: #84818A;
}

// cards css
.new_cards {
    background: #fff;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    max-height: 300px;
    // margin-bottom: 4.2rem;
}
.cards_detail {
    display: flex;
    margin: 2rem 0px;
    justify-content: space-between;
}
img.user_imgs {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
}
.user_card {
    display: flex;
    padding: 1.5rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;
}
.user_detail {
    display: flex;
    align-items: center;
    cursor: pointer;
}
h4.user {
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    color: #202020;
    text-transform: capitalize;
}
.user_name {
    margin: 0px 10px;
}
p.user_info {
    font-size: 12px;
    font-weight: 400;
    color: #84818A;
    margin-bottom: 0px;
}
span.contract {
    font-size: 12px;
    font-weight: 600;
    color: #0063dc;
}
.user_hours {
    min-width: 66px;
    height: 24px;
    padding: 0 10px;
    background: #a5b4ff3d;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    cursor: pointer;

    &.deactive{
      background-color: #dc354536;
     
      .contract{
         color: #f00;;
      }
    }
}

.user_proff {
    display: flex;
    justify-content: space-between;
    // height: 100%;
    // max-height: 200px;
    padding: 1rem 1.5rem;
}
ul.user_list {
    list-style-type: none;
    padding: 0px;
}
a.id {
    font-size: 13px;
    font-weight: 400;
    line-height: 32px;
    color: #84818A;
}
li.list_names {
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    color: #202020;
    line-height: 32px;
}
i.fa.fa-phone {
    transform: rotate(97deg);
    margin-right: 4px;
    color: #84818A;
}

.dot::before { /* add the new bullet point */
    display: inline-block;
      content: '';
      -webkit-border-radius: 0.375rem;
      border-radius: 2px;
      height:7px;
      width: 7px;
      margin-right: 0.5rem;
      background-color: #bdbdbd;
  }
  
  .dot::before { /* and another color */
    background-color: #00AAFF;
  }
  

//   table css
.table_section {
  // height: 100%;
  background: #fff;
  padding: 12px !important;
  border-radius: 12px;
  margin-top: 32px;
  box-shadow: 0px 0px 2px #cccccc40 !important;
  max-height: calc(100vh - 250px);
  overflow: auto;
  padding-top: 0px !important;
}
th.table_data {
    border-top: none !important;
    border-bottom: none !important;
    font-size: 14px;
    font-weight: 500;
    color: #84818A;
   
}
i.fa.fa-ellipsis-h {
    font-size: 15px;
    color: #84818A;
}
  td.table_dats  {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    vertical-align: middle;
    // text-transform: capitalize;
}
td.overflow_data{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 106px;
}
tr.data_row {
    height: 60px;
    border-bottom: 1px solid #E8E8E8;

    // &.drapStart{
    //   box-shadow: 1px 1px #000;
    // }

    &.subCategory{
      .user_detail{
        margin-left: 10px;
        &::before{
          content: "-";
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
    &.subSubCategory{
      .user_detail{
        margin-left: 20px;
        &::before{
          content: "--";
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
}
img.arrow {
    height: 16px;
    width: 16px;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}
.btn-secondary.focus, .btn-secondary:focus {
    color: #fff;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none!important;
  
}

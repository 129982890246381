tr.deactive{
    background-color: #ffd9dd;
}

.list_name{
  min-width: 90px;  
}

.activedeactiveicon {
    font-size: 18px;
    line-height: normal;
    vertical-align: middle;
}

 

.nowrap {
    white-space: nowrap;
}

  

.modal-dialog-md {
    max-width: 900px !important;
}

@import '../../../scss/mixins';

.main-wrapper {
  background-color: #eee;

  main.main {
    width: calc(100% - 250px);
    // padding-left: 0px;
    // padding-right: 0px;
      padding-left: 30px;
   padding-right: 30px;
  }
}

.editLogo {
  display: inline-block;
  position: relative;
  cursor: pointer;

  >i {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.logocls {
  width: 100%;
  max-width: 185px !important;
}

.dash-title {
  position: relative;
  background-color: #f6f8fb;
  padding: 11px 19px;
  font-size: 28px;
  line-height: normal;
  border-radius: 6px;
  margin-bottom: 30px;

  .total {
    font-size: 14px;
    float: right;
    font-weight: 600;
    margin-top: 10px;
  }
}

.main-sidebar {
  // position: relative;
//  background-image: url('../../../../public/assets/img/background_img.png');
//  background-size: cover;
//  background-attachment: fixed;
  background-color: #fff;
  max-width: 252px;
  min-width: 250px;
  // width: 100%;
  // min-height: 100vh;
  // margin-top: -72px;
  // border-right: 1px solid #E8E8E8;
  // .sidebar-brand img {
    
  // }
}
.sidebar_scrolling {
  overflow-y: auto;
  position: fixed;
  position: fixed;
  z-index: 999;
  background: #fff;
  margin-top: 0;
  padding-top: 2rem;
  height: 100vh;
  width: 250px;
}
@media (max-width: 767px) {
  .main-wrapper main.main {
    width: 100%;
  }
}

@media (max-width: 467px) {
  .form-row.uploaded-image-row>div {
    max-width: 50%;

    .uploaded-image img {
      height: 100px;
    }
  }
}

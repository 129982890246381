.profileImage {
    width: 72px;
    height: 72px;
    border-radius: 50%;
}

.profileImageLabel {
    margin: 0;
    position: relative;

    >i {
        position: absolute;
        bottom: -3px;
        right: -3px;
    }
}

.pprofile1 {
    padding: 21px 22px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 12px;
}

p.bg-light.rounded.py-2.fieldcls {
    background-color: #775DA62b !important;

}

.ViewUser {
    // font-weight: 500;
    color: #202020;
    font-weight: 600;
    // font-size: 36px;
    font-size: 22px;
    margin-bottom: 32px;
}
.w-fit{
    width: fit-content;
}
.profiles{
    font-size: 12px !important;
    padding: 7px 10px !important;
}
.profiles i.material-icons.prob {
    font-size: 15px;
    margin: 0px 4px;
}
.profiles:focus {
    box-shadow: none !important;
}
.normalFlex{
    display: flex;
justify-content: space-between;
flex-wrap: wrap;
gap: 2px;
}
@import '../../../scss/mixins';
a.btn.barlink.text-primary {
  // position: absolute;
  left: 6px;
  z-index: 9;
  top: 13px;
}
a.btn.barlink.text-primary {
  left: 20px;
  width: 35px;
  height: 35px;
  padding: 7px 9px;
}
.headerSearch {
  left: 6px;
}
.ml-1.nameFont b {
  font-size: 14px;
  font-weight: 500;
  color: #202020;
}
.ml-1.nameFont p{
  font-size: 12px;
  font-weight: 400;
  // color: #fff;
}
.dropdown-item { 
  padding: 0.25rem 1rem !important;
}
.grayCls{
  color: #84818A;
}
.ml-1.nameFont {
  line-height: 18px;
}
.sidebar-brand.p-3.pt-4.text-left.pl-5 {
  // border-bottom: 1px solid #E8E8E8;
  padding: 0px !important;
  height: 72px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding-left: 50px !important;
}
i.fas.fa-bars{
  color: #4aa2dc;
  font-size: 18px;
}
.main-navbar {
  // background-image: linear-gradient(to left, #2062AD,#24A2DB);
  // background-color: #fff;
  // color: #fff;
  height: 72px;
  // padding-left: 250px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #E8E8E8 !important;
  position: sticky;
  width: 100%;
  background: #fff;
  top: 0;
  z-index: 999;

  &.min-sidebar {
    // padding-left: 60px;
    .barWidth {
      width: auto;
  }
  .headeLogo {
    width: 58px;
}
  }

  .nav-link {
    padding: 10px;
  }

  .nav-link-user {
    >img {
      width: 32px;
      height: 32px;
    }
  }
}

.ml-1.nameFont b {
  font-size: 14px;
  font-weight: 500;
  // color: #fff !important;
}
input.Searchbar:focus {
  outline: none;
}
.headerSearch {
  position: relative;
  font-size: 14px;
  color: #000;
 

  .Searchbar {
    background: #F9F9F9;
    border: none;
    padding: 10px 35px;
    border-radius: 8px;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
  }

  >i {
    position: absolute;
    top: 50%;
    color: #84818A;
    transform: translateY(-50%);
    cursor: pointer;

    &.fa-search {
      left: 10px;
    }

    &.fa-times {
      right: 10px;
    }
  }
}

.notification {
  color: #CACCCF;
  font-size: 27px;
  transform: rotate(29deg);
  margin-right: 22px;
  margin-top: 16px;
}

.reddot {
  background-color: red;
  padding: 2px 5px;
  border-radius: 14px;
  color: #ffff;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -13px;
  right: -2px;
  border: 3px solid #ffff;
  transform: rotate(333deg);
}
.min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
  padding: 8px;
  border-radius: 6px !important;
  margin-right: 10px !important;
  width: 41px;
  justify-content: center;

}


.min-sidebar ~ div > .main-sidebar a.col_side {
  padding: 8px;
  border-radius: 6px !important;
  margin-right: 10px !important;
  width: 41px;
  justify-content: center;

  &:hover{
    justify-content: center;
    margin-right: 0px !important;

  }

}

.min-sidebar {
  ~div>.main-sidebar {
    width: 65px;
    min-width: 58px;

    .sidebar-brand {
      width: 0;
      visibility: hidden;
    }

    .nav-link span {
      display: none;
    }
    .nav-link.hoverclass{
      justify-content: center !important;
      
    }
    & i{
margin-right: 0px !important;
    }
    & i.sidenv{
      display: block !important;
      margin-right: 0px !important;
  }
  
  }

  ~div>main.main {
    width: calc(100% - 58px);
  }
}

.min-sidebar {
  ~div>.main-sidebar {
    width: 65px;
    min-width: 58px;

    .sidebar-brand {
      width: 0;
      visibility: hidden;
    }
    .submenu {
      display: block;
  }
    a.col_side span {
      display: none;
    }
    .card.card-body.sides{
      background-color: transparent;
    }
  }

  ~div>main.main {
    width: calc(100% - 58px);
  }
}
nav.navbar.navbar-expand-lg.main-navbar.min-sidebar {
  padding-left: 10px;
}
.headeLogo{
  width: 70px;
}
.barWidth{
  width: 262px;
}
.headerFonts{
  font-size: 14px;
}
.headerFonts.dropdown-toggle::after{
  position: absolute !important;
  top: 18px !important;
  right: 0 !important;
}
.dropdown-menu-right {
  font-size: 14px;
}
.dropdown-menu-right.dropdown-menu.shadow.bg_hover.show {
  left: 0 !important;
}
@media(max-width:600px){
  .mobHide{
    display: none;
  }
  .dropdown-menu-right.dropdown-menu.shadow.bg_hover.show {
    left: -100px !important;
}
.dropdown-menu.shadow a{
  padding-left: 0 !important;
}
}
@media(max-width:400px){
  .headerFonts{
    font-size: 12px !important;
  }
  .main-navbar .nav-link {
    padding: 10px 5px !important;
}
.main-navbar {
   padding-left: 10px !important; 
}
.navbar .dropdown.ml-auto {
  // right: -5px;
}
.headerFonts.dropdown-toggle::after {
  right: -3px !important;
}
}